<template>
	<!-- 禁止页面横向滚动 -->
  <div class="content" style="overflow-x: hidden;">
	  <!-- 回到顶部功能 -->
	  <goTop></goTop>
    <div class="banner-wrap">
      <el-image
        class="banner"
        fit="fill"
        :src="require('@/assets/img/banner_zpqs.png')"
      />
      <div class="banner-text-wrap">
        <beatText text="加入我们，领略风景" fontSize="50px" />
        <beatText
          text="尊重人才 价值/塑造人才品质/ 致力人才成长"
          fontSize="30px"
        />
      </div>
    </div>

    <el-card class="box-card">
      <div v-for="(item, index) in list" :key="index" class="card-item">
        <div style="display: flex">
          <span class="card-title">{{ item.title }}</span>
          <div class="card-tag">
            <div
              class="card-tag-item"
              :style="{
                color: index == 0 ? '#194FA8' : '#EC2B47',
                background: index == 0 ? '#BED7FF' : '#FFD2D6',
              }"
              v-for="(item, index) in item.tag"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="card-text">
          {{ item.text }}
        </div>
        <div class="update-date">
          {{ getDate() + "  更新" }}
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
	// banner文字
import beatText from "@/components/beat-text";
// 回到顶部
	import goTop from '@/components/goTop.vue'
export default {
  components: { 
	  beatText,
	   goTop
	  },
  data() {
    return {
      list: [
        {
          title: "大数据工程师",
          text: "10人/本科/经验1-3年",
          date: new Date(),
          tag: ["全职", "杭州"],
        },
        {
          title: "Java后端工程师",
          text: "5人/本科/经验1-3年",
          date: new Date(),
          tag: ["全职", "杭州"],
        },
        {
          title: "Vue前端工程师",
          text: "5人/本科/经验1-3年",
          date: new Date(),
          tag: ["全职", "杭州"],
        },
        {
          title: "渠道销售经理",
          text: "20人/本科/经验1-3年",
          date: new Date(),
          tag: ["全职", "杭州"],
        },
      ],
      isAdmin: false,
    };
  },
  mounted() {
    let that = this;
    setTimeout(() => {
      that.isAdmin = true;
      that.$refs.beatText2.startAnim();
    }, 1500);
  },
  methods: {
    getDate() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      return `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  .banner-wrap {
    position: relative;
    .banner {
      width: 100%;
      height: 500px;
    }
    .banner-text-wrap {
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
      .banner-title {
        font-size: 54px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
      }
      .banner-text {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 300;
        color: #ffffff;
      }
    }
  }

  .box-card {
    transform: translateY(-15.5%);
    width: 75%;
	padding: 14px 20px;
	background: #FFFFFF;
	box-shadow: 0px 5px 18px 2px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
    .card-item {
      background: #f5f5f5;
      margin-top: 15px;
      margin-top: 30px;
      position: relative;
      box-sizing: border-box;
      padding: 48px 49px 46px 49px;
      .card-title {
        font-size: 28px;
		margin-right: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #2D2D2D;
      }

      .card-tag {
        display: flex;
        align-items: center;
        .card-tag-item {
          height: fit-content;
          color: #ec2b47;
          padding: 3px 8px;
          margin-right: 10px;
        }
      }

      .card-text {
        width: 177px;
        height: 20px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #5B5B5B;
        line-height: 36px;
        margin-top: 5px;
      }

      .update-date {
        position: absolute;
        right: 48px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
</style>